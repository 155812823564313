<template>
  <div class="profile-name">
    <p>{{ name != "" ? name : "CAMP" }}</p>
    <span v-if="job">{{ job }}</span>
  </div>
</template>

<script setup lang="ts">
  type Name = {
    name: string;
    job?: string;
  };

  const props = defineProps<Name>();
  const { name, job } = props;
</script>

<style scoped lang="scss">
  .profile-name {
    @apply min-w-[130px] text-right mr-3 ml-6 flex flex-col justify-center;
    p {
      @apply text-base mb-1 leading-none;
    }
    span {
      @apply text-[12px] text-neutral-500 leading-none;
    }
  }
</style>
